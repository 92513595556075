<template>
  <Content>
    <PersonalityCarousel
      :redirect-path="'/result'"
      :info-page="false"
      :slides="sliderList"
      :content-color="'#320959'"
      @changeSlide="changeSlide($event)"/>
  </Content>
</template>

<script>
import PersonalityCarousel from '@components/PersonalityCarousel/PersonalityCarousel.vue';
import sussesStrength from '@assets/success-invitation/success-strenght.svg';
import trustJourney from '@assets/success-invitation/success-journey.svg';
import trustSupport from '@assets/success-invitation/success-support.svg';
import { mapGetters } from 'vuex';

export default {
  name: 'SuccessInvitation',
  components: { PersonalityCarousel },
  computed: {
    ...mapGetters({
      getPersonalityTest: 'invitation/getPersonalityTest',
    }),
    sliderList() {
      return [
        {
          id: 0,
          src: sussesStrength,
          type: 'usual',
          title: `YOU HELPED ${this.getPersonalityTest.name.toUpperCase()} FIND`,
          desc: 'hidden strengths!',
          textButton: 'TAP TO CONTINUE',
        },
        {
          id: 1,
          src: trustJourney,
          type: 'usual',
          title: `${this.getPersonalityTest.name.toUpperCase()} IS READY TO CONTINUE`,
          desc: 'the journey',
          textButton: 'TAP TO CONTINUE',
        },
        {
          id: 2,
          src: trustSupport,
          type: 'usual',
          title: 'THANKS TO YOUR ANONYMOUS',
          desc: 'support',
          textButton: 'TAP TO CONTINUE',
        },
      ];
    },
  },
  data: () => ({
    currentSlide: 0,
  }),
  methods: {
    changeSlide(numberSlide) {
      this.currentSlide = numberSlide;
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    height: 100%;
  }
</style>
